import React, { ReactNode, ReactElement } from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import { cn } from "../../../utils/shorthands";
import * as scss from "./grid.scss";

export type GridProps = {
	/** Children should be `Grid.Section` components */
	children: ReactNode;
} & DataObserveKey &
	VisualComponent;

export function Grid(props: GridProps): JSX.Element {
	return (
		<div
			data-observe-key={props["data-observe-key"]}
			data-component="grid"
			className={cn(scss.grid, props.className)}
			style={props.style}
		>
			{props.children}
		</div>
	);
}

type SizeProp =
	| "full"
	| "threefourths"
	| "¾"
	| "twothirds"
	| "⅔"
	| "half"
	| "½"
	| "third"
	| "⅓"
	| "fourth"
	| "¼";

export type SectionProps = {
	/** Children should likely be `Card`'s but can be anything */
	children: ReactElement | ReactElement[];
	/** Defaults to "full", controls the size of the section */
	size?: SizeProp;
} & DataObserveKey &
	VisualComponent;

function Section(props: SectionProps): JSX.Element {
	const { size = "full", children, className, style } = props;
	return (
		<div
			data-observe-key={props["data-observe-key"]}
			data-component="grid-section"
			className={cn(scss.gridSection, sizeClass(size), className)}
			style={style}
		>
			{children}
		</div>
	);
}

function sizeClass(sizeProp: SizeProp): string {
	switch (sizeProp) {
		case "¾":
			return scss["threefourths"];
		case "⅔":
			return scss["twothirds"];
		case "½":
			return scss["half"];
		case "⅓":
			return scss["third"];
		case "¼":
			return scss["fourth"];
		default:
			return scss[sizeProp];
	}
}

Grid.Section = Section;
