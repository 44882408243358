import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { DocPageMeta, HeaderSection, ContentSection, Example, Header } from "../../../../src/docs";
import { Paragraph } from "../text/paragraph/paragraph";
import { TextContainer } from "../text/text-container/text-container";
import { Ol } from "../text/list/list";
import { Content } from "./content/content";
import { Grid } from "./grid/grid";
import { Card } from "./card/card";
import { StructureExampleToggles } from "./common";

export const Meta: DocPageMeta = {
	title: "🧭 Guide",
	category: "Structure",
	notepad: null, //no notepad for guide
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="🧭 Guide"
			subTitle="This guide explains how our structure fit together like Russian dolls."
		/>
		<ContentSection>
			<TextContainer article>
				<Header.H2>Nesting</Header.H2>
				<Paragraph>
					We have five components to control the layout of content on a page. They’re all invisible,
					except for the <GatsbyLink to="../Card">card</GatsbyLink> component. They can be nested
					inside each other, like Russian dolls. The nesting order, from largest to smallest doll,
					is as follows:
				</Paragraph>
				<Ol
					items={[
						<>
							<GatsbyLink to="../Grid and Grid.Section">Grid</GatsbyLink>. Wrap it around
							Grid.Section components to display the <GatsbyLink to="../Card">cards</GatsbyLink>{" "}
							inside them in a grid.
						</>,
						<>
							<GatsbyLink to="../Grid and Grid.Section">Grid.Section</GatsbyLink>. Wrap it around
							one or multiple <GatsbyLink to="../Card">cards</GatsbyLink>. Use it to control their
							size. It also provides appropriate padding and makes cards responsive, by adjusting
							their width on smaller viewports.
						</>,
						<>
							<GatsbyLink to="../Card">Card</GatsbyLink>. Wrap it around{" "}
							<GatsbyLink to="../Content.Layout">layout</GatsbyLink>,{" "}
							<GatsbyLink to="../Content">content</GatsbyLink>, or other “regular” components. Use
							it to visually group related information, controls and actions.
						</>,
						<>
							<GatsbyLink to="../Content.Layout">Content.Layout</GatsbyLink>. Wrap it around
							multiple <GatsbyLink to="../Content">content</GatsbyLink> components. Use it to
							control their padding, alignment, and positioning.
						</>,
						<>
							<GatsbyLink to="../Content">Content</GatsbyLink>. Wrap it around one or multiple
							“regular” components, such as a{" "}
							<GatsbyLink to="/lib/components/Text/Text container">text-container</GatsbyLink>. Use
							it to control the padding, position, and aligmnent of the components.
						</>,
					]}
				/>
				<Header.H2>Interactive Visualization</Header.H2>
				<Paragraph>
					An interactive visualization of this nested layout system be seen below. Borders and
					backgrounds have been added to make the normally invisble components visible. This styling
					can be toggled on and off with the checkboxes below the example.
				</Paragraph>
				<Example fn={visualization} customBar={({ id }) => <StructureExampleToggles id={id} />} />
			</TextContainer>
		</ContentSection>
	</>
);

const visualization = () => (
	<Grid>
		<Grid.Section size="half">
			<Card>
				<Card.Header>Card A</Card.Header>
				<Content>
					<Paragraph>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit. Donec
						laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur efficitur justo
						non egestas.
					</Paragraph>
				</Content>
			</Card>
		</Grid.Section>
		<Grid.Section size="half">
			<Card>
				<Card.Header>Card B</Card.Header>
				<Content>
					<Paragraph>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque magna massa, elementum
						ac odio ut, consequat blandit massa. Sed posuere placerat mauris, nec vulputate risus
						faucibus a. Vestibulum id lacus tincidunt, blandit nulla a, semper nisi. Aliquam sit
						amet gravida felis. Suspendisse potenti. Nam in sollicitudin nisi.
					</Paragraph>
				</Content>
			</Card>
		</Grid.Section>
		<Grid.Section size="twothirds">
			<Card>
				<Card.Header>Card C</Card.Header>
				<Content.Layout>
					<Content>
						<Paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit. Donec
							laoreet massa justo, at sagittis nulla volutpat vitae.
						</Paragraph>
					</Content>
					<Content>
						<Paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit. Donec
							laoreet massa justo, at sagittis nulla volutpat vitae.
						</Paragraph>
					</Content>
				</Content.Layout>
			</Card>
			<Card>
				<Card.Header>Card D</Card.Header>
				<Content>
					<Paragraph>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit. Donec
						laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur efficitur justo
						non egestas.
					</Paragraph>
				</Content>
			</Card>
		</Grid.Section>
		<Grid.Section size="third">
			<Card>
				<Card.Header>Card E</Card.Header>
				<Content.Layout>
					<Content>
						<Paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
						</Paragraph>
					</Content>
					<Content>
						<Paragraph>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
						</Paragraph>
					</Content>
				</Content.Layout>
			</Card>
		</Grid.Section>
	</Grid>
);
